import React from "react";
import {
    useTranslate,
    IResourceComponentsProps,
    useShow,
} from "@pankod/refine-core";
import {
    Paper,
} from "@pankod/refine-mui";

import { ISpace } from "interfaces";

export const SpaceShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { queryResult } = useShow<ISpace>();
    const record = queryResult.data?.data;
    
    return (
        <>
            <Paper
                sx={{
                    paddingX: { xs: 3, md: 2 },
                    paddingY: { xs: 2, md: 3 },
                    my: 0.5,
                }}
            >
                {record?.name}
            </Paper>
        </>
    );
};