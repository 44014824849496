import {
    createTheme,
    DarkTheme as DefaultDarkTheme,
    LightTheme as DefaultLightTheme,
    responsiveFontSizes,
} from "@pankod/refine-mui";

const LightTheme = createTheme({
    ...DefaultLightTheme,
    palette: {
        background: {
            default: "#F6F4F3"
        },
        type: 'light',
        primary: {
            main: '#DE3C4B',
        },
        secondary: {
            main: '#061E34',
        },
    },
    shape: {
        borderRadius: 2,
    },
    overrides: {
        MuiButton: {
            root: {
                background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                border: 0,
                borderRadius: 3,
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
                color: 'white',
                height: 48,
                padding: '0 30px',
            },
        },
    },
    props: {
        MuiList: {
            dense: true,
        },
        MuiMenuItem: {
            dense: true,
        },
        MuiTable: {
            size: 'small',
        },
        MuiButton: {
            size: 'small',
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
    },
    timeLine: {
        color: {
            pending: "#fff7e6",
            ready: "#e6fffb",
            delivered: "#e6f7ff",
            cancelled: "#fff1f0",
            onTheWay: "#f6ffed",
        },
        dotColor: {
            pending: "#ffa940",
            ready: "#36cfc9",
            delivered: "#40a9ff",
            cancelled: "#ff4d4f",
            onTheWay: "#73d13d",
        },
    },
} as any);

const DarkTheme = createTheme({
    ...DefaultDarkTheme,
    timeLine: {
        color: {
            pending: "#f2a400",
            ready: "#00c2a2",
            delivered: "#0083c2",
            cancelled: "#c60d00",
            onTheWay: "#62c400",
        },
        dotColor: {
            pending: "#9f5700",
            ready: "#196966",
            delivered: "#00579f",
            cancelled: "#a60001",
            onTheWay: "#386d19",
        },
    },
} as any);

const DarkThemeWithResponsiveFontSizes = responsiveFontSizes(DarkTheme);
const LightThemeWithResponsiveFontSizes = responsiveFontSizes(LightTheme);

export { LightThemeWithResponsiveFontSizes, DarkThemeWithResponsiveFontSizes };