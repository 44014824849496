import { Refine } from "@pankod/refine-core";
import { KBarProvider } from "@pankod/refine-kbar";
import {
  Layout,
  ErrorComponent,
  AuthPage,
  LightTheme,
  CssBaseline,
  ThemeProvider,
  GlobalStyles,
  RefineSnackbarProvider,
  notificationProvider,
} from "@pankod/refine-mui";
import routerProvider from "@pankod/refine-react-router-v6";
import { firestoreDatabase, firebaseAuth } from './firebaseConfig';

import { useTranslation } from "react-i18next";

import {
  ConstructionOutlined, Room
} from "@mui/icons-material";
import { ColorModeContextProvider } from "contexts";

import { Title, Header, Sider, OffLayoutArea } from "components";
import { ToolList } from "pages/tools";
import { SpaceList, SpaceShow } from "pages/spaces";

const App: React.FC = () => {
  const { t, i18n } = useTranslation();
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  return (
    <KBarProvider>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles
          styles={{ html: { WebkitFontSmoothing: "auto" } }}
        />
        <RefineSnackbarProvider>
          <Refine
            routerProvider={routerProvider}
            dataProvider={firestoreDatabase.getDataProvider() as any}
            authProvider={firebaseAuth.getAuthProvider()}
            notificationProvider={notificationProvider}
            i18nProvider={i18nProvider}
            Layout={Layout}
            Title={Title}
            Header={Header}
            LoginPage={AuthPage}
            OffLayoutArea={OffLayoutArea}
            Sider={Sider}
            resources={[
              {
                name: "spaces",
                list: SpaceList,
                show: SpaceShow,
                icon: <Room />,
              },
              {
                name: "tools",
                list: ToolList,
                icon: <ConstructionOutlined />,
              },
            ]}
            catchAll={<ErrorComponent />}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          />
        </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </KBarProvider>
  );
};

export default App;