import { useRouterContext } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";

import { LogoWhiteIcon } from "components/icons/logo-white";

type TitleProps = {
    collapsed: boolean;
};

export const Title: React.FC<TitleProps> = ({ collapsed }) => {
    const { Link } = useRouterContext();

    return (
        <Link to="/">
            <Box
                sx={{
                    height: "72px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {collapsed ? (
                    <LogoWhiteIcon sx={{ color: "#F3A712" }} />
                ) : (
                    <img src="/images/maker-pass-dark.png" width="160" alt="maker PASS" />
                )}
            </Box>
        </Link>
    );
};
