import { useState } from "react";
import { useTranslate, BaseKey } from "@pankod/refine-core";
import {
    Badge,
    Card,
    CardActions,
    CardHeader,
    Box,
    IconButton,
    CardMedia,
    CardContent,
    Typography,
    Tooltip,
    Popover,
    Button,
    Divider,
    TextField,
} from "@pankod/refine-mui";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";

import { ISpace } from "interfaces";

type PropductItem = {
    updateStock?: (changedValue: number, clickedTool: ISpace) => void;
    tool: ISpace;
    show: (id: BaseKey) => void;
};

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


export const SpaceItem: React.FC<PropductItem> = ({
    tool,
    show,
    updateStock,
}) => {
    const t = useTranslate();
    const { id, name, website } = tool;
    // const images = tool.images || [];

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                height: "100%",
            }}>
            {/* {images.length && <CardMedia
                component="img"
                height="194"
                image={images[0].data}
                alt="Paella dish"
            />} */}
            <CardContent>
                <Tooltip title={name}>
                    <Typography variant="h5" component="div">
                        {name}
                    </Typography>
                </Tooltip>
                {/* <Typography variant="body2">
                    {description}
                </Typography> */}
                {/* {updateStock && (
                    <TextField
                        type="number"
                        margin="dense"
                        size="small"
                        value={tool.stock || 0}
                        onChange={(e) => {
                            e.preventDefault();
                            updateStock(parseInt(e.target.value, 10), tool);
                        }}
                    />
                )} */}
            </CardContent>
            <CardActions>
                <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }} component="div">
                    <IconButton
                        aria-describedby={popoverId}
                        onClick={handleClick}
                        sx={{ marginRight: "10px", marginTop: "4px" }}
                        aria-label="settings"
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Popover
                        id={popoverId}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            onClick={() => {
                                show(id);
                                setAnchorEl(null);
                            }}
                            size="small"
                            startIcon={<EditIcon />}
                            sx={{
                                padding: "5px 10px",
                            }}
                        >
                            {t("stores.buttons.edit")}
                        </Button>
                    </Popover>
                </Box>
                {/* <Button size="small">Learn More</Button> */}
            </CardActions>
        </Card>
    );
};