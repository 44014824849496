import { initializeFirebase, FirebaseAuth, FirestoreDatabase } from "refine-firebase";

export const firebaseConfig = {
    apiKey: "AIzaSyAMQrY_15CqYPzi6CaCqXEfHHNJi50PxKg",
    authDomain: "makerpassapp.firebaseapp.com",
    projectId: "makerpassapp",
    storageBucket: "makerpassapp.appspot.com",
    messagingSenderId: "868061745049",
    appId: "1:868061745049:web:56f6e74fc290430d1ecd08",
    measurementId: "G-G1RN8YY67Q"
};

export const firebaseApp = initializeFirebase(firebaseConfig);

export const firebaseAuth = new FirebaseAuth(undefined, firebaseApp);

export const firestoreDatabase = new FirestoreDatabase({
    firebaseApp,
});